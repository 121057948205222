import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
} from "@/components/ui/sheet.tsx";
import { useSubscriptionFilterDrawerStore } from "@/store/drawers/filterDrawerStore.ts";
import Stack from "@/components/atoms/Stack.tsx";
import Text from "@/components/atoms/text/Text.tsx";
import SubscriptionsFilter from "@/components/sections/SubscriptionsFilter.tsx";
import { Button } from "@/components/ui/button.tsx";
import { initialSubscriptionFilter } from "@/lib/schemas/filter.schema.ts";
import { useSubscriptionFilterStore } from "@/store/subscriptionFilterStore.ts";

const SubscriptionFilterDrawer = () => {
  const { isOpen, setIsOpen, showNKOM } = useSubscriptionFilterDrawerStore(
    (state) => ({
      isOpen: state.isOpen,
      setIsOpen: state.setIsOpen,
      showNKOM: state.showNKOM,
    }),
  );
  const setFilter = useSubscriptionFilterStore((state) => state.setFilter);

  const handleFilterReset = () => {
    setFilter(initialSubscriptionFilter);
    handleClose();
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Sheet open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <SheetContent
        side={"left"}
        className="p-3 w-full sm:w-[540px] bg-gray-50 overflow-y-scroll"
      >
        <Stack className={"transition-all"}>
          <SheetHeader className={"text-left"}>
            <Stack gap={"none"}>
              <SheetTitle>Filter</SheetTitle>
              <Text size={"sm"} variant={"subtle"}>
                Spesifiser dine ønsker og trykk ferdig
              </Text>
            </Stack>
          </SheetHeader>
          <SubscriptionsFilter showNKOM={showNKOM} />
          <div
            className={"sticky bottom-0 p-2 rounded w-full mt-4 backdrop-blur"}
          >
            <Button onClick={handleClose} className={"w-full"}>
              Ferdig
            </Button>
            <Button
              onClick={handleFilterReset}
              variant={"ghost"}
              className={"w-full"}
            >
              Tilbakestill filter
            </Button>
          </div>
        </Stack>
      </SheetContent>
    </Sheet>
  );
};

export default SubscriptionFilterDrawer;
