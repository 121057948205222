import Card from "@/components/atoms/Card.tsx";
import Stack from "@/components/atoms/Stack.tsx";
import Group from "@/components/atoms/Group.tsx";
import Text from "@/components/atoms/text/Text.tsx";
import { Slider } from "@/components/ui/slider.tsx";
import { Switch } from "@/components/ui/switch.tsx";
import { initialSubscriptionFilter } from "@/lib/schemas/filter.schema.ts";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs.tsx";
import { useSubscriptionFilterStore } from "@/store/subscriptionFilterStore.ts";
import { AnimatePresence, motion } from "framer-motion";
import IconCalendar from "@/components/icons/IconCalendar.tsx";
import IconSignalLte from "@/components/icons/IconSignalLte.tsx";
import IconCircuitCellPlus from "@/components/icons/IconCircuitCellPlus.tsx";
import IconPhoneCall from "@/components/icons/IconPhoneCall.tsx";
import IconGlobe from "@/components/icons/IconGlobe.tsx";
import IconDeviceSim from "@/components/icons/IconDeviceSim.tsx";
import IconDiscountCheck from "@/components/icons/IconDiscountCheck.tsx";

const SubscriptionsFilter = ({
  className,
  showNKOM = false,
}: {
  className?: string;
  showNKOM?: boolean;
}) => {
  const { filter, setFilter } = useSubscriptionFilterStore();

  return (
    <Stack className={className} ariaLabel={"Filter for mobilabonnement"}>
      <Card className={"w-full"}>
        <Stack gap={"md"}>
          <Group justify={"apart"} w={"full"}>
            <Text size={"md"}>Data</Text>
            <Text size={"md"} fw={"semi"}>
              {filter.data === 100 ? "Fri data" : `${filter.data} GB`}
            </Text>
          </Group>
          <Slider
            name={"Data"}
            max={100}
            step={1}
            min={0}
            value={[filter.data]}
            onValueChange={(e) => {
              const value = e[0];

              if (value === 1000) {
                setFilter({ ...filter, data: 100, freeData: true });
              } else {
                setFilter({ ...filter, data: value, freeData: false });
              }

              setFilter({ ...filter, data: e[0] });
            }}
          />
          <Group justify={"apart"} w={"full"}>
            <Text variant={"subtle"} size={"sm"}>
              0 GB
            </Text>
            <Text variant={"subtle"} size={"sm"}>
              Fri data
            </Text>
          </Group>
        </Stack>
      </Card>
      <Card className={"w-full"}>
        <Stack gap={"lg"} w={"full"}>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Fri data"}
                checked={filter.freeData || false}
                onCheckedChange={(checked) => {
                  if (checked) {
                    setFilter({ ...filter, data: 100, freeData: true });
                  } else {
                    setFilter({
                      ...filter,
                      data: initialSubscriptionFilter.data,
                      freeData: false,
                    });
                  }
                }}
              />
              <Text>Fri data</Text>
            </Group>
            <IconSignalLte />
          </Group>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Maks hastighet"}
                checked={filter.maxSpeed || false}
                onCheckedChange={(checked) =>
                  setFilter({ ...filter, maxSpeed: checked })
                }
              />
              <Text>Maks hastighet</Text>
            </Group>
            <IconCircuitCellPlus />
          </Group>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Fri tale"}
                checked={filter.freeTalkTime || false}
                onCheckedChange={(checked) =>
                  setFilter({ ...filter, freeTalkTime: checked })
                }
              />
              <Text>Fri tale</Text>
            </Group>
            <IconPhoneCall />
          </Group>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Bruk i EU / EØS"}
                checked={filter.euRoaming || false}
                onCheckedChange={(checked) =>
                  setFilter({ ...filter, euRoaming: checked })
                }
              />
              <Text>Bruk i EU / EØS</Text>
            </Group>
            <IconGlobe />
          </Group>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Datarollover"}
                checked={filter.dataRollOver || false}
                onCheckedChange={(checked) =>
                  setFilter({ ...filter, dataRollOver: checked })
                }
              />
              <Text>Datarollover</Text>
            </Group>
            <IconCalendar />
          </Group>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Kontantkort"}
                checked={filter.prepaid || false}
                onCheckedChange={(checked) =>
                  setFilter({
                    ...filter,
                    data: checked ? 0 : initialSubscriptionFilter.data,
                    prepaid: checked,
                  })
                }
              />
              <Text>Kontantkort</Text>
            </Group>
            <IconDeviceSim />
          </Group>
          <Group justify={"apart"} w={"full"}>
            <Group>
              <Switch
                title={"Vis kampanjepriser"}
                checked={!filter.hideCampaign}
                onCheckedChange={(checked) =>
                  setFilter({
                    ...filter,
                    hideCampaign: !checked,
                  })
                }
              />
              <Text>Vis kampanjepriser</Text>
            </Group>
            <IconDiscountCheck />
          </Group>
        </Stack>
      </Card>
      <Card className={"w-full"}>
        <Stack gap={"sm"}>
          <Text>Nettverk</Text>
          <Tabs
            value={filter.network}
            className={"w-full"}
            defaultValue={"all"}
          >
            <TabsList className={"grid w-full grid-cols-4"}>
              <TabsTrigger
                onClick={() => setFilter({ ...filter, network: "all" })}
                value="all"
                tabIndex={0}
                aria-controls={null}
              >
                Alle
              </TabsTrigger>
              <TabsTrigger
                onClick={() => setFilter({ ...filter, network: "telenor" })}
                value="telenor"
                tabIndex={0}
                aria-controls={null}
              >
                Telenor
              </TabsTrigger>
              <TabsTrigger
                onClick={() => setFilter({ ...filter, network: "telia" })}
                value="telia"
                tabIndex={0}
                aria-controls={null}
              >
                Telia
              </TabsTrigger>
              <TabsTrigger
                onClick={() => setFilter({ ...filter, network: "ice" })}
                value="ice"
                tabIndex={0}
                aria-controls={null}
              >
                Ice
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </Stack>
      </Card>
      <Card className={"w-full"}>
        <Tabs value={filter.type} className={"w-full"}>
          <TabsList className={"grid w-full grid-cols-2"}>
            <TabsTrigger
              onClick={() => setFilter({ ...filter, type: "private" })}
              value="private"
              tabIndex={0}
              aria-controls={null}
            >
              Privat
            </TabsTrigger>
            <TabsTrigger
              onClick={() => setFilter({ ...filter, type: "family" })}
              value={"family"}
              tabIndex={0}
              aria-controls={null}
            >
              Familie
            </TabsTrigger>
          </TabsList>
        </Tabs>
      </Card>
      <AnimatePresence initial={false} mode={"sync"}>
        {filter.type === "private" && !filter.maxAge && !filter.minAge && (
          <motion.div
            key={filter.type}
            initial={{ opacity: 0, x: -10 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 10 }}
            className={"flex w-full"}
          >
            <Card className={"w-full"}>
              <Stack gap={"md"}>
                <Group justify={"apart"} w={"full"}>
                  <Text size={"md"}>Din alder</Text>
                  <Text size={"md"} fw={"semi"}>
                    {filter.age} År
                  </Text>
                </Group>
                <Slider
                  name={"Din alder"}
                  max={100}
                  step={1}
                  value={[filter.age || 0]}
                  onValueChange={(e) => {
                    setFilter({ ...filter, age: e[0] });
                  }}
                />
                <Group justify={"apart"} w={"full"}>
                  <Text variant={"subtle"} size={"sm"}>
                    0 År
                  </Text>
                  <Text variant={"subtle"} size={"sm"}>
                    100 År
                  </Text>
                </Group>
              </Stack>
            </Card>
          </motion.div>
        )}
        {showNKOM && (
          <a
            target={"_blank"}
            rel={"noopener noreferrer"}
            href={
              "https://nkom.no/ekom-markedet/godkjentordning-for-prissammenlikningstjenester"
            }
          >
            <img
              className={"px-2"}
              src={"/nkom_black.png"}
              alt={"Nkom godkjennelsemerke 2024"}
            />
          </a>
        )}
      </AnimatePresence>
    </Stack>
  );
};

export default SubscriptionsFilter;
