import { create } from "zustand";

interface DrawerState {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  showNKOM: boolean;
  open: (_showNKOM?: boolean) => void;
  close: () => void;
}

export const useSubscriptionFilterDrawerStore = create<DrawerState>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
  showNKOM: false,
  open: (_showNKOM = false) => set({ isOpen: true, showNKOM: _showNKOM }),
  close: () => set({ isOpen: false, showNKOM: false }),
}));
